<template>
  <div class="whole">
    <Nav></Nav>
    <div class="catalog-whole desktop-present">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页>>{{ catalogTitle }}
      </div>
      <!-- 视频 -->
      <div class="shuhua-con">
        <!-- 轮播部分 -->
        <!-- <div class="shuahu-swiper" v-if="videoList[0]">
          <div
            class="shuhua-swiper-list"
            @mouseenter="stopTimer()"
            @mouseleave="restartTimer()"
          >
            <div
              class="shuhua-swiper-del"
              v-for="(m, shuhuaindex) in videoList[0].posts"
              :key="m.id + 'shuhua_' + shuhuaindex"
              :style="{ backgroundImage: `url(` + m.posterURL + `)` }"
              :class="{ active: picIndex == shuhuaindex }"
              @click="onLookPost(picIndex)"
            ></div>
          </div>
        </div> -->
        <!-- 短视频 -->
        <div class="shortVideo" v-if="videoList[0]">
          <div class="short-title">
            <div class="short-t" @click="onGoDoublePage(videoList[0].id)">
              {{ videoList[0].title }}
            </div>
            <div class="title-more" @click="onGoDoublePage(videoList[0].id)">
              更多...
            </div>
          </div>
          <div class="short-mobile-con">
            <div
              class="shortvideo-list"
              v-for="(short, shortindex) in videoList[0].posts"
              :key="short.postId + 'shortmobile_' + shortindex"
            >
              <video
                :src="short.videoURL"
                controls
                :ref="'video_' + shortindex + '_' + short.postId"
                @playing="
                  onVideoStatusChange(
                    'video_' + shortindex + '_' + short.postId,
                    $event
                  )
                "
                loop
                width="876"
                height="523"
              ></video>
              <div
                class="shortvideo-img"
                v-if="short.posterShow"
                :style="{ backgroundImage: `url(` + short.imgURL + `)` }"
                @mouseenter="onNoposter(1, shortindex)"
              ></div>
              <div class="shortvideo-title" v-if="short.posterShow">
                {{ short.title }}
              </div>
            </div>
          </div>
          <div class="short-con">
            <div
              class="shortvideo-list"
              v-for="(post, shortindex) in videoList[0].posts"
              :key="post.postId + 'shortvideo_' + shortindex"
              @click="onLookVideoPost(post.postId)"
            >
              <video
                :src="post.videoURL"
                :poster="post.posterURL"
                :ref="'video_' + post.postId"
                @mouseenter="onVideoEnter('video_' + post.postId)"
                @mouseleave="onVideoLeave('video_' + post.postId)"
                loop
                :muted="post.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="post.mute"
                @mouseenter="onVideoEnter('video_' + post.postId)"
                @mouseleave="onVideoLeave('video_' + post.postId)"
                @click.stop="post.mute = !post.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!post.mute"
                @mouseenter="onVideoEnter('video_' + post.postId)"
                @mouseleave="onVideoLeave('video_' + post.postId)"
                @click.stop="post.mute = !post.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 视频素材 -->
        <div class="materialVideo" v-if="videoList[2]">
          <div class="material-title" @click="onGoDoublePage(videoList[2].id)">
            {{ videoList[2].title }}(城市景观)
          </div>
          <div class="material-con">
            <div
              class="material-list"
              v-for="(k, materialindex) in videoList[2].posts"
              :key="k.postId + 'material_' + materialindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 自然奇观 -->
        <div class="natureVideo" v-if="videoList[3]">
          <div class="nature-title" @click="onGoDoublePage(videoList[3].id)">
            {{ videoList[3].title }}
          </div>
          <div class="nature-con">
            <div
              class="nature-list"
              v-for="(k, natureindex) in videoList[3].posts"
              :key="k.id + 'nature_' + natureindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 人文生活 -->
        <div class="humanLife" v-if="videoList[4]">
          <div class="humanLife-title" @click="onGoDoublePage(videoList[4].id)">
            {{ videoList[4].title }}
          </div>
          <div class="humanLife-mobile-con">
            <div
              class="humanLife-list"
              v-for="(k, humanLifeindex) in videoList[4].posts"
              :key="k.id + 'humanLife_' + humanLifeindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
          <div class="humanLife-con">
            <div
              class="humanLife-list"
              v-for="(k, humanLifeindex) in videoList[4].posts"
              :key="k.id + 'humanLife_' + humanLifeindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 重点工程 -->
        <div class="mainProjectVideo" v-if="videoList[5]">
          <div
            class="mainProject-title"
            @click="onGoDoublePage(videoList[5].id)"
          >
            {{ videoList[5].title }}
          </div>
          <div class="mainProject-con">
            <div
              class="mainProject-list"
              v-for="(k, mainProjectindex) in videoList[5].posts"
              :key="k.id + 'mainProject_' + mainProjectindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 灵感创意 -->
        <div class="creativityVideo" v-if="videoList[6]">
          <div
            class="creativity-title"
            @click="onGoDoublePage(videoList[6].id)"
          >
            {{ videoList[6].title }}
          </div>
          <!-- <div class="creativity-mobile-con">
            <div class="creativity-list" v-for="(k, creativityindex) in videoList[6].posts" :key="k.id + 'creativity_' + creativityindex">
              <video
                :src="k.videoURL"
                controls
                :ref="'video_' + creativityindex + '_' + k.id"
                @playing="onVideoStatusChange('video_' + creativityindex + '_' + k.id, $event)"
                loop
                width="876"
                height="523"
              ></video>
              <div class="creativity-img" v-if="k.posterShow" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @mouseenter="onNoposter(6, creativityindex)"></div>
              <div class="creativity-text" v-if="k.posterShow">{{ k.title }}</div>
            </div>
          </div> -->
          <div class="creativity-con">
            <div
              class="creativity-list"
              v-for="(k, creativityindex) in videoList[6].posts"
              :key="k.id + 'creativity_' + creativityindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 摄影课堂 -->
        <div class="Photography" v-if="videoList[7]">
          <div
            class="Photography-title"
            @click="onGoDoublePage(videoList[7].id)"
          >
            {{ videoList[7].title }}
          </div>
          <div class="Photography-con">
            <div
              class="Photography-list"
              v-for="(k, Photographyindex) in videoList[7].posts"
              :key="k.id + 'Photography_' + Photographyindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="catalog-whole mobile-present">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页>>{{ catalogTitle }}
      </div>
      <!-- 视频 -->
      <div class="shuhua-con">
        <!-- 轮播部分 -->
        <div class="shuahu-swiper" v-if="videoList[0]">
          <div
            class="shuhua-swiper-list"
            @mouseenter="stopTimer()"
            @mouseleave="restartTimer()"
          >
            <div
              class="shuhua-swiper-del"
              v-for="(m, shuhuaindex) in videoList[0].posts"
              :key="m.id + 'shuhua_' + shuhuaindex"
              :style="{ backgroundImage: `url(` + m.imgURL + `)` }"
              :class="{ active: picIndex == shuhuaindex }"
              @click="onLookPost(picIndex)"
            ></div>
          </div>
        </div>
        <!-- 短视频 -->
        <div class="shortVideo" v-if="videoList[1]">
          <div class="short-title">
            <div class="short-t" @click="onGoDoublePage(videoList[1].id)">
              {{ videoList[1].title }}
            </div>
            <div class="title-more" @click="onGoDoublePage(videoList[1].id)">
              更多...
            </div>
          </div>
          <div class="short-mobile-con">
            <div
              class="shortvideo-list"
              v-for="(short, shortindex) in videoList[1].posts"
              :key="short.id + 'shortmobile_' + shortindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="short.videoURL"
                controls
                :ref="'video_' + shortindex + '_' + short.id"
                @playing="
                  onVideoStatusChange(
                    'video_' + shortindex + '_' + short.id,
                    $event
                  )
                "
                loop
                width="876"
                height="523"
              ></video>
              <div
                class="shortvideo-img"
                v-if="short.posterShow"
                :style="{ backgroundImage: `url(` + short.imgURL + `)` }"
                @mouseenter="onNoposter(1, shortindex)"
              ></div>
              <div class="shortvideo-title" v-if="short.posterShow">
                {{ short.title }}
              </div>
            </div>
          </div>
          <div class="short-con">
            <div
              class="shortvideo-list"
              v-for="(post, shortindex) in videoList[1].posts"
              :key="post.id + 'shortvideo_' + shortindex"
              @click="onLookVideoPost(post.postId)"
            >
              <video
                :src="post.videoURL"
                :poster="post.posterURL"
                :ref="'video_' + post.id"
                @mouseenter="onVideoEnter('video_' + post.id)"
                @mouseleave="onVideoLeave('video_' + post.id)"
                loop
                :muted="post.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="post.mute"
                @mouseenter="onVideoEnter('video_' + post.id)"
                @mouseleave="onVideoLeave('video_' + post.id)"
                @click.stop="post.mute = !post.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!post.mute"
                @mouseenter="onVideoEnter('video_' + post.id)"
                @mouseleave="onVideoLeave('video_' + post.id)"
                @click.stop="post.mute = !post.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 视频素材 -->
        <div class="materialVideo" v-if="videoList[2]">
          <div class="material-title" @click="onGoDoublePage(videoList[2].id)">
            {{ videoList[2].title }}(城市景观)
          </div>
          <div class="material-con">
            <div
              class="material-list"
              v-for="(k, materialindex) in videoList[2].posts"
              :key="k.id + 'material_' + materialindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 自然奇观 -->
        <div class="natureVideo" v-if="videoList[3]">
          <div class="nature-title" @click="onGoDoublePage(videoList[3].id)">
            {{ videoList[3].title }}
          </div>
          <div class="nature-con">
            <div
              class="nature-list"
              v-for="(k, natureindex) in videoList[3].posts"
              :key="k.id + 'nature_' + natureindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 人文生活 -->
        <div class="humanLife" v-if="videoList[4]">
          <div class="humanLife-title" @click="onGoDoublePage(videoList[4].id)">
            {{ videoList[4].title }}
          </div>
          <div class="humanLife-mobile-con">
            <div
              class="humanLife-list"
              v-for="(k, humanLifeindex) in videoList[4].posts"
              :key="k.id + 'humanLife_' + humanLifeindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
          <div class="humanLife-con">
            <div
              class="humanLife-list"
              v-for="(k, humanLifeindex) in videoList[4].posts"
              :key="k.id + 'humanLife_' + humanLifeindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 重点工程 -->
        <div class="mainProjectVideo" v-if="videoList[5]">
          <div
            class="mainProject-title"
            @click="onGoDoublePage(videoList[5].id)"
          >
            {{ videoList[5].title }}
          </div>
          <div class="mainProject-con">
            <div
              class="mainProject-list"
              v-for="(k, mainProjectindex) in videoList[5].posts"
              :key="k.id + 'mainProject_' + mainProjectindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 灵感创意 -->
        <div class="creativityVideo" v-if="videoList[6]">
          <div
            class="creativity-title"
            @click="onGoDoublePage(videoList[6].id)"
          >
            {{ videoList[6].title }}
          </div>
          <!-- <div class="creativity-mobile-con">
            <div class="creativity-list" v-for="(k, creativityindex) in videoList[6].posts" :key="k.id + 'creativity_' + creativityindex">
              <video
                :src="k.videoURL"
                controls
                :ref="'video_' + creativityindex + '_' + k.id"
                @playing="onVideoStatusChange('video_' + creativityindex + '_' + k.id, $event)"
                loop
                width="876"
                height="523"
              ></video>
              <div class="creativity-img" v-if="k.posterShow" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @mouseenter="onNoposter(6, creativityindex)"></div>
              <div class="creativity-text" v-if="k.posterShow">{{ k.title }}</div>
            </div>
          </div> -->
          <div class="creativity-con">
            <div
              class="creativity-list"
              v-for="(k, creativityindex) in videoList[6].posts"
              :key="k.id + 'creativity_' + creativityindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
        <!-- 摄影课堂 -->
        <div class="Photography" v-if="videoList[7]">
          <div
            class="Photography-title"
            @click="onGoDoublePage(videoList[7].id)"
          >
            {{ videoList[7].title }}
          </div>
          <div class="Photography-con">
            <div
              class="Photography-list"
              v-for="(k, Photographyindex) in videoList[7].posts"
              :key="k.id + 'Photography_' + Photographyindex"
              @click="onLookVideoPost(k.postId)"
            >
              <video
                :src="k.videoURL"
                :poster="k.posterURL"
                :ref="'video_' + k.postId"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                loop
                :muted="k.mute"
              ></video
              ><i
                class="mute el-icon-headset active"
                v-if="k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
              <i
                class="mute el-icon-headset"
                v-if="!k.mute"
                @mouseenter="onVideoEnter('video_' + k.postId)"
                @mouseleave="onVideoLeave('video_' + k.postId)"
                @click.stop="k.mute = !k.mute"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
var timers = [];
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      catalogTitle: "视频",
      videoList: [], // 全部的视频列表
      picIndex: 0, // 书画轮播
    };
  },
  created() {
    this.reload();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next();
      this.reload();
    }
  },
  methods: {
    reload() {
      var thiz = this;
      console.log("quet", thiz.$route.query.title);

      RD.index()
        .catalogByName(thiz.$route.query.title)
        .then((data) => {
          console.log("catalogdata", data);
          if (data.length == 0) {
            thiz.$message("当前栏目无稿件");
          }
          data.forEach((i) => {
            i.posts.forEach((k) => {
              k.posterURL = RD.video().id(k.postId).poster_URL();
              k.videoURL = RD.video().id(k.postId).svideo_URL();
              k.posterShow = true;
            });
          });

          timers = setInterval(() => {
            this.swipernext();
          }, 3000);
          thiz.videoList = data;
          console.log("posts", thiz.swiperList);
        });
    },
    swipernext() {
      this.picIndex++;
      if (this.picIndex == this.videoList[0].posts.length) {
        this.picIndex = 0;
      }
    },
    stopTimer() {
      clearInterval(timers);
    },
    restartTimer() {
      timers = setInterval(() => {
        this.swipernext();
      }, 3000);
    },
    onLookPost(picId) {
      var postId = this.videoList[0].posts[picId].postId;
      this.$router.push({
        path: "/post_" + postId + ".html",
      });
    },
    onLookVideoPost(id) {
      this.$router.push({
        path: "/post_" + postId + ".html",
      });
    },
    // 进入二级页面
    onGoDoublePage(postId) {
      this.$router.push({
        path: "/fixTopic.html",
        query: { id: postId },
      });
    },
    // 鼠标移入
    onNoposter(listindex, videoIndex) {
      this.videoList[listindex].posts[videoIndex].posterShow = false;
    },
    // 播放状态
    onVideoStatusChange(currentplay, event) {
      var thiz = this;
      console.log("event", event);
      for (var key in thiz.$refs) {
        if (key.substr(0, 6) == "video_") {
          if (currentplay == key) {
            // 当前正在播放的
            console.log("currentplay");
          } else {
            // 其他的都暂停
            console.log("sibliingpause", thiz.$refs, thiz.$refs[key]);
            thiz.$refs[key][0].pause();
          }
        }
      }
    },
    onVideoEnter(ref) {
      this.$refs[ref][0].play();
    },
    onVideoLeave(ref) {
      this.$refs[ref][0].pause();
    },
  },
};
</script>

<style scoped lang="less">
.catalog-whole.desktop-present {
  height: auto;
  background-color: #fff;
  min-height: 61.6rem;
  .catalogtitle {
    height: 4rem;
    text-align: left;
    width: 120rem;
    margin: 0 auto;
    font-size: 1.6rem;
    padding-top: 1.5rem;
    display: flex;
    border-bottom: 0.1rem solid #bfbfbf;
    .icon {
      width: 2rem;
      height: 2rem;
      background: no-repeat url(../assets/weizhi.png);
      background-size: cover;
      margin-right: 1rem;
    }
  }
  .shuhua-con {
    width: 120rem;
    margin: 3rem auto;
    height: 100%;
    overflow: hidden;
    .shuahu-swiper {
      height: 56rem;
      width: 100%;
      .shuhua-swiper-list {
        height: 100%;
        width: 100%;
        position: relative;
        .shuhua-swiper-del {
          position: absolute;
          top: 0rem;
          left: 0rem;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }
        .shuhua-swiper-del.active {
          opacity: 1;
        }
      }
    }
    .shortVideo {
      width: 120rem;
      margin: 1rem auto;
      .short-title {
        height: 6rem;
        line-height: 6rem;
        font-size: 2rem;
        position: relative;
        .short-t {
          position: absolute;
          left: 48%;
          top: 0rem;
          cursor: pointer;
        }
        .title-more {
          position: absolute;
          right: 1rem;
          top: 1rem;
          font-size: 1.3rem;
          cursor: pointer;
        }
      }
      .short-mobile-con {
        display: none;
      }
      .short-con {
        height: 51.4rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
        .shortvideo-list:nth-child(1) {
          width: 50%;
          height: 50rem;
        }
        .shortvideo-list {
          width: 28.5rem;
          height: 23.6rem;
          margin-right: 1rem;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .shortvideo-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .shortvideo-title {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
        }
        .shortvideo-list:nth-child(4) {
          position: absolute;
          top: 26.3rem;
          left: 61.1rem;
        }
        .shortvideo-list:nth-child(5) {
          position: absolute;
          top: 26.3rem;
          left: 90.6rem;
        }
        .shortvideo-list:nth-child(1n + 6) {
          display: none;
        }
      }
    }
    .materialVideo {
      width: 120rem;
      margin: 1rem auto;
      .material-title {
        width: 21rem;
        margin: 0 auto;
        cursor: pointer;
        height: 6rem;
        line-height: 6rem;
        font-size: 2rem;
      }
      .material-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        .material-list {
          width: 29.5rem;
          height: 22.5rem;
          margin-right: 1rem;
          margin-bottom: 1rem;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .material-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .material-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
    .natureVideo {
      width: 120rem;
      margin: 1rem auto;
      .nature-title {
        width: 21rem;
        margin: 0 auto;
        cursor: pointer;
        height: 6rem;
        line-height: 6rem;
        font-size: 2rem;
      }
      .nature-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        .nature-list {
          width: 29.5rem;
          height: 22.5rem;
          margin-right: 1rem;
          margin-bottom: 1rem;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .nature-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .nature-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
    .humanLife {
      width: 120rem;
      margin: 1rem auto;
      .humanLife-title {
        width: 21rem;
        margin: 0 auto;
        cursor: pointer;
        height: 6rem;
        line-height: 6rem;
        font-size: 2rem;
      }
      .humanLife-mobile-con {
        display: none;
      }
      .humanLife-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        position: relative;
        .humanLife-list:nth-child(2) {
          width: 43.3rem;
          height: 44.1rem;
        }
        .humanLife-list:nth-child(4) {
          position: absolute;
          left: 0rem;
          top: 23.4rem;
        }
        .humanLife-list:nth-child(5) {
          position: absolute;
          left: 84.1rem;
          top: 23.4rem;
        }
        .humanLife-list:nth-child(1n + 6) {
          display: none;
        }
        .humanLife-list {
          width: 35.5rem;
          height: 20.6rem;
          position: relative;
          margin-right: 2.7rem;
          margin-bottom: 3rem;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .humanLife-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .humanLife-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
    .mainProjectVideo {
      width: 120rem;
      margin: 1rem auto;
      .mainProject-title {
        width: 21rem;
        margin: 0 auto;
        cursor: pointer;
        height: 6rem;
        line-height: 6rem;
        font-size: 2rem;
      }
      .mainProject-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        .mainProject-list {
          width: 29.5rem;
          height: 22.5rem;
          margin-right: 1rem;
          margin-bottom: 1rem;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .mainProject-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .mainProject-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
    .creativityVideo {
      width: 120rem;
      margin: 1rem auto;
      .creativity-title {
        width: 21rem;
        margin: 0 auto;
        cursor: pointer;
        height: 6rem;
        line-height: 6rem;
        font-size: 2rem;
      }
      .creativity-mobile-con {
        display: none;
      }
      .creativity-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        height: 45rem;
        position: relative;
        .creativity-list:nth-child(3) {
          width: 59rem;
          height: 45rem;
        }
        .creativity-list:nth-child(4) {
          position: absolute;
          left: 0rem;
          top: 23.5rem;
        }
        .creativity-list:nth-child(5) {
          position: absolute;
          left: 30.5rem;
          top: 23.5rem;
        }
        .creativity-list:nth-child(1n + 6) {
          display: none;
        }
        .creativity-list {
          width: 29.5rem;
          height: 22.5rem;
          margin-right: 1rem;
          margin-bottom: 1rem;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .creativity-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .creativity-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
    .Photography {
      width: 120rem;
      margin: 1rem auto;
      .Photography-title {
        width: 21rem;
        margin: 0 auto;
        cursor: pointer;
        height: 6rem;
        line-height: 6rem;
        font-size: 2rem;
      }
      .Photography-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        height: 45rem;
        position: relative;
        .Photography-list {
          width: 29.5rem;
          height: 22.5rem;
          margin-right: 1rem;
          margin-bottom: 1rem;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .creativity-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .creativity-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
  }
}
.catalog-whole.mobile-present {
  height: auto;
  background-color: #fff;
  min-height: 800px;
  .catalogtitle {
    height: 40px;
    text-align: left;
    width: 750px;
    margin: 0 auto;
    font-size: 16px;
    padding-top: 15px;
    display: flex;
    border-bottom: 0.1rem solid #bfbfbf;
    .icon {
      width: 20px;
      height: 20px;
      background: no-repeat url(../assets/weizhi.png);
      background-size: cover;
      margin-right: 10px;
    }
  }
  .shuhua-con {
    width: 750px;
    margin: 30px auto;
    height: 100%;
    overflow: hidden;
    .shuahu-swiper {
      height: 340px;
      width: 100%;
      .shuhua-swiper-list {
        height: 100%;
        width: 100%;
        position: relative;
        .shuhua-swiper-del {
          position: absolute;
          top: 0rem;
          left: 0rem;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }
        .shuhua-swiper-del.active {
          opacity: 1;
        }
      }
    }
    .shortVideo {
      width: 750px;
      margin: 10px auto;
      .short-title {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        position: relative;
        .short-t {
          position: absolute;
          left: 48%;
          top: 0rem;
          cursor: pointer;
        }
        .title-more {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .short-mobile-con {
        display: none;
      }
      .short-con {
        height: 320px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
        .shortvideo-list:nth-child(1) {
          width: 50%;
          height: 304px;
        }
        .shortvideo-list {
          width: 173px;
          height: 143px;
          margin-right: 1rem;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .shortvideo-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .shortvideo-title {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 4rem;
            line-height: 4rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
        .shortvideo-list:nth-child(4) {
          position: absolute;
          top: 161px;
          left: 382px;
        }
        .shortvideo-list:nth-child(5) {
          position: absolute;
          top: 161px;
          left: 561px;
        }
        .shortvideo-list:nth-child(1n + 6) {
          display: none;
        }
      }
    }
    .materialVideo {
      width: 750px;
      margin: 10px auto;
      .material-title {
        width: 180px;
        margin: 0 auto;
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
      }
      .material-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        .material-list {
          width: 179px;
          height: 136px;
          margin-right: 10px;
          margin-bottom: 10px;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .material-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .material-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
    .natureVideo {
      width: 750px;
      margin: 10px auto;
      .nature-title {
        width: 160px;
        margin: 0 auto;
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
      }
      .nature-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        .nature-list {
          width: 179px;
          height: 136px;
          margin-right: 10px;
          margin-bottom: 10px;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .nature-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .nature-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
    .humanLife {
      width: 750px;
      margin: 10px auto;
      .humanLife-title {
        width: 160px;
        margin: 0 auto;
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
      }
      .humanLife-mobile-con {
        display: none;
      }
      .humanLife-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        position: relative;
        .humanLife-list:nth-child(2) {
          width: 263px;
          height: 267px;
        }
        .humanLife-list:nth-child(4) {
          position: absolute;
          left: 0rem;
          top: 141px;
        }
        .humanLife-list:nth-child(5) {
          position: absolute;
          left: 512px;
          top: 141px;
        }
        .humanLife-list:nth-child(1n + 6) {
          display: none;
        }
        .humanLife-list {
          width: 215px;
          height: 125px;
          position: relative;
          margin-right: 2.7rem;
          margin-bottom: 3rem;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .humanLife-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .humanLife-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
    .mainProjectVideo {
      width: 750px;
      margin: 10px auto;
      .mainProject-title {
        width: 160px;
        margin: 0 auto;
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
      }
      .mainProject-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        .mainProject-list {
          width: 179px;
          height: 136px;
          margin-right: 10px;
          margin-bottom: 10px;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .mainProject-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .mainProject-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
    .creativityVideo {
      width: 750px;
      margin: 10px auto;
      .creativity-title {
        width: 160px;
        margin: 0 auto;
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
      }
      .creativity-mobile-con {
        display: none;
      }
      .creativity-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        height: 275px;
        position: relative;
        .creativity-list:nth-child(3) {
          width: 358px;
          height: 273px;
        }
        .creativity-list:nth-child(4) {
          position: absolute;
          left: 0rem;
          top: 144px;
        }
        .creativity-list:nth-child(5) {
          position: absolute;
          left: 186px;
          top: 144px;
        }
        .creativity-list:nth-child(1n + 6) {
          display: none;
        }
        .creativity-list {
          width: 179px;
          height: 136px;
          margin-right: 10px;
          margin-bottom: 10px;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .creativity-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .creativity-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
    .Photography {
      width: 750px;
      margin: 10px auto;
      .Photography-title {
        width: 160px;
        margin: 0 auto;
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
      }
      .Photography-con {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 102%;
        overflow: hidden;
        height: 280px;
        position: relative;
        .Photography-list {
          width: 179px;
          height: 136px;
          margin-right: 10px;
          margin-bottom: 10px;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .mute {
            display: block;
            position: absolute;
            right: 2rem;
            top: 2rem;
            font-size: 2rem;
            height: 3rem;
            width: 3rem;
            border: 2px solid white;
            border-radius: 2rem;
            line-height: 3rem;
            color: white;
          }
          .mute.active {
            color: red;
          }
          .creativity-img {
            position: absolute;
            left: 0rem;
            top: 0rem;
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
          .creativity-text {
            position: absolute;
            bottom: 0rem;
            left: 0rem;
            width: 100%;
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
